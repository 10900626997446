<template>
  <div class="demo-section">
    <div
      class="columns is-centered is-vcentered"
      :class="isMobile ? ' is-marginless' : ''"
    >
      <div class="column">
        <div class="section">
          <div class="subtitle is-1 has-text-Chroma-08 is-family-bitter">
            {{ $t('home.dashboard.title') }}
          </div>
          <div class="subtitle is-6 has-text-Dark is-family-bitter">
            {{ $t('home.dashboard.subtitle') }}
          </div>
        </div>
      </div>
      <div class="column" :class="isMobile ? ' is-paddingless' : ''">
        <b-image custom-class="full-image" src="/static/Dashboard.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomeDemo',
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/_variables';
.button {
  border-radius: 8px;
}
.icon {
  font-size: 1.6rem;
}
.subtitle.is-6 {
  line-height: 1.25;
  letter-spacing: 0.29px;
}
.subtitle.is-1 {
  font-size: 2.9rem;
  line-height: 1.087;
  letter-spacing: -0.49px;
}
</style>
