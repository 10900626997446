<template>
  <div class="card">
    <div class="card-image">
      <figure class="image">
        <img :src="img" alt="Placeholder image" />
      </figure>
    </div>
    <div class="card-content has-text-centered is-family-inter">
      <div
        v-html="title"
        class="title is-spaced has-text-Chroma-08 has-text-weight-bold"
      ></div>
      <div class="subtitle has-text-Dark">
        {{ subtitle }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SCard',
  props: {
    title: {
      type: String,
      default: 'Default Title',
    },
    subtitle: {
      type: String,
      default: 'Default Subtitle',
    },
    button: {
      type: String,
      default: 'Default Button',
    },
    img: {
      type: String,
      default: 'https://bulma.io/images/placeholders/1280x960.png',
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/_variables';
.card {
  border-radius: 10px;
  border: 1px solid $Chroma-03-75;
  width: 370px;
  height: auto;
}
.card-content {
  padding: 3rem 2rem;
}
.title {
  letter-spacing: -0.32px;
  line-height: 1.2;
}
.subtitle {
  line-height: 1.714;
  font-size: 14px;
}
.button:hover {
  box-shadow: 0px 1px 4px 0px $Chroma-04-40;
  border: 1px solid $Chroma-04-40;
  color: $Chroma-07;
}
.button:focus {
  box-shadow: 0px 1px 4px 0px $Chroma-04-40;
  border: 1px solid $Chroma-04-40;
  color: $Chroma-07;
}
.button {
  color: $Chroma-07;
  border-radius: 8px;
  padding: 1.3em 4em;
  letter-spacing: 0.23px;
  border: 1px solid $Chroma-04-40;
  font-weight: $weight-medium;
  font-family: $font-family-inter;
  box-shadow: 0px 1px 4px 0px $Chroma-04-40;
}
</style>
