<template>
  <div class="home-banner2">
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-two-fifths">
          <b-image :src="img" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomeBanner2',
  computed: {
    img() {
      return this.$i18n.locale === 'es'
        ? '/static/Tagline.svg'
        : '/static/Tagline-en.svg'
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/_variables';
.section {
  background-color: $Chroma-08;
  padding: 5rem 3rem;
}
</style>
