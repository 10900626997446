<template>
  <div class="home-tabs" :class="{ 'custom-padding': !isMobile }">
    <div class="columns is-centered" :class="{ 'is-marginless': isMobile }">
      <div class="column is-paddingless">
        <div class="section">
          <div v-if="!isMobile" class="spacer"></div>
          <div v-if="!isMobile" class="spacer"></div>
          <s-tabs
            :tab1="$t('home.tabs.tab-1.tab')"
            :tab2="$t('home.tabs.tab-2.tab')"
            :tab3="$t('home.tabs.tab-3.tab')"
            @change-tab="changeTab($event)"
            @tab-click="tabClick($event)"
          />
          <div class="spacer"></div>
          <s-tabs-item
            v-show="isTab1"
            :about="$t('home.tabs.title')"
            :section="$t('home.tabs.tab-1.tab')"
            :content="$t('home.tabs.tab-1.content')"
          />
          <s-tabs-item
            v-show="isTab2"
            :about="$t('home.tabs.title')"
            :section="$t('home.tabs.tab-2.tab')"
            :content="$t('home.tabs.tab-2.content')"
          />
          <s-tabs-item v-show="false" :section="$t('home.tabs.tab-2.tab')" />
        </div>
      </div>
      <div class="column is-flex" :class="isMobile ? 'is-paddingless' : ''">
        <b-image :src="tabImage" custom-class="full-image" />
      </div>
    </div>
  </div>
</template>
<script>
import STabs from '@/components/STabs'
import STabsItem from '@/components/STabsItem'

export default {
  name: 'HomeTabs',
  components: {
    STabs,
    STabsItem,
  },
  data() {
    return {
      defaultTab: 'tab1',
    }
  },
  computed: {
    isTab1() {
      return this.defaultTab == 'tab1'
    },
    isTab2() {
      return this.defaultTab == 'tab2'
    },
    isTab3() {
      return this.defaultTab == 'tab3'
    },
    tabImage() {
      return this.defaultTab == 'tab1'
        ? '/static/quienes-somos.png'
        : '/static/que-hacemos.png'
    },
  },
  methods: {
    changeTab(tabName) {
      this.defaultTab = tabName
    },
    tabClick(element) {
      this.$emit('tab-click', element)
      this.defaultTab == 'tab1'
        ? this.changeTab('tab1')
        : this.changeTab('tab2')
    },
  },
}
</script>
<style scoped>
.spacer {
  padding: 1rem;
}
.custom-padding {
  padding-left: 3rem;
  padding-right: 0rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}
</style>
