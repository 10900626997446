<template>
  <b-navbar ref="nav" :class="{ 'padding-01': !isMobile }" :fixed-top="isFixed">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ name: 'Home' }">
        <img src="/static/snd-logo.svg" />
      </b-navbar-item>
    </template>
    <template #end>
      <b-navbar-item tag="div" :class="{ 'is-gradiant': isMobile }">
        <div
          class="buttons is-family-inter has-text-weight-semibold"
          :class="{ 'buttons-mobile': isMobile }"
        >
          <div
            @click="buttonClick('tabs')"
            class="button is-ghost has-text-white"
          >
            {{ $t('home.navbar.button-1') }}
          </div>
          <div
            @click="buttonClick('solutions')"
            class="button is-ghost has-text-white"
          >
            {{ $t('home.navbar.button-2') }}
          </div>
          <div
            @click="buttonClick('contact')"
            class="button is-ghost has-text-white"
          >
            {{ $t('home.navbar.button-3') }}
          </div>
          <div v-if="!isMobile" class="divider"></div>
          <div
            @click="switchLang(lang)"
            class="button is-ghost has-text-white is-family-bitter"
          >
            <span class="icon has-text-Chroma-03">
              <i class="mdi mdi-comment-quote-outline"></i>
            </span>
            <span> {{ $t('home.navbar.button-4') }} </span>
          </div>
          <div v-if="!isMobile" class="divider"></div>
          <div
            @click="goToLogin"
            class="button is-ghost has-text-white is-family-bitter"
          >
            <span class="icon has-text-Chroma-03">
              <i class="mdi mdi-account-outline"></i>
            </span>
            <span>{{ $t('home.navbar.button-5') }} </span>
          </div>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>
<script>
export default {
  name: 'SnNavBar',
  data() {
    return {
      isFixed: false,
    }
  },
  methods: {
    stickyNavbar() {
      const navbar = this.$refs.nav.$el
      const sticky = navbar.offsetTop

      window.onscroll = () => {
        if (window.pageYOffset >= sticky) {
          this.isFixed = true
        } else {
          this.isFixed = false
        }
      }
    },
    buttonClick(element) {
      this.$emit('button-click', element)
    },
    goToLogin() {
      window.location.href = 'http://hub.snd.com.mx/login'
    },
    switchLang(lang) {
      this.$i18n.locale = lang
    },
  },
  mounted() {
    this.stickyNavbar()
  },
  computed: {
    lang() {
      return this.$i18n.locale == 'en' ? 'es' : 'en'
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/_variables';
.navbar {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-brand a.navbar-item {
  border-radius: 8px;
}
.navbar-brand a.navbar-item:hover {
  background-color: $Chroma-03-30;
}
.buttons-mobile {
  flex-direction: column;
  align-items: start;
}

.is-gradiant {
  background: rgba(50, 26, 120, 0.95);
  background: linear-gradient(
    90deg,
    rgba(50, 26, 120, 0.95) 0%,
    rgba(73, 44, 157, 0.95) 100%
  );
}
.navbar {
  background: rgba(50, 26, 120, 0.95);
  background: linear-gradient(
    90deg,
    rgba(50, 26, 120, 0.95) 0%,
    rgba(73, 44, 157, 0.95) 100%
  );
  box-shadow: 0px 2px 8px -1px $Darkest-40;
}
.button.is-ghost:hover {
  background: $Chroma-03-30;
  text-decoration: none;
}
.navbar-item img {
  max-height: 2rem;
}
.button {
  border-radius: 8px;
  font-size: 14px;
}
.divider {
  border-left: 2px dotted $Chroma-03;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 2rem;
}
.icon {
  font-size: 1.55rem;
}
</style>
