import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/terminos-y-condiciones',
    name: 'TermsAndConditions',
    component: () => import('@/views/TermsAndConditions'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  //Scroll positon between routes
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes,
})

export default router
