<template>
  <div class="sn-tabs">
    <div class="columns is-flex is-vcentered is-uppercase is-family-inter">
      <div class="column is-narrow">
        <div
          @click="changeTab('tab1')"
          class="title has-text-weight-bold"
          :class="tabs.tab1 ? 'is-selected' : 'is-clickable'"
        >
          {{ tab1 }}
        </div>
      </div>
      <div class="divider"></div>
      <div class="column is-narrow">
        <div
          @click="changeTab('tab2')"
          class="title has-text-weight-bold"
          :class="tabs.tab2 ? 'is-selected' : 'is-clickable'"
        >
          {{ tab2 }}
        </div>
      </div>
      <div class="divider"></div>
      <div class="column is-narrow">
        <div
          @click="tabClick('contact')"
          class="title has-text-weight-bold"
          :class="tabs.tab3 ? 'is-selected' : 'is-clickable'"
        >
          {{ tab3 }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SnMenu',
  data() {
    return {
      tabs: {
        tab1: true,
        tab2: false,
        tab3: false,
      },
    }
  },
  methods: {
    changeTab(tabName) {
      for (const tab in this.tabs) {
        if (tab == tabName) {
          this.tabs[tab] = true
        } else {
          this.tabs[tab] = false
        }
      }
      this.$emit('change-tab', tabName)
    },
    tabClick(element) {
      this.$emit('tab-click', element)
    },
  },
  props: {
    tab1: {
      type: String,
      default: 'Tab 1',
    },
    tab2: {
      type: String,
      default: 'Tab 2',
    },
    tab3: {
      type: String,
      default: 'Tab 3',
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/_variables';
.title {
  color: $Chroma-07;
  font-size: 0.65rem;
  letter-spacing: 0.75px;
}
.is-selected {
  color: $Chroma-05-60;
}
.divider {
  border-left: 1px solid $Chroma-03;
  height: 0.85rem;
}
</style>
