<template>
  <div class="home">
    <home-banner img="/static/snd-logo.svg" @icon-click="scrollTo('tabs')" />
    <hr />
    <the-navbar @button-click="scrollTo($event)" />
    <home-tabs @tab-click="scrollTo($event)" ref="tabs" />
    <home-banner2 />
    <home-solutions ref="solutions" />
    <home-demo />
    <home-news />
    <home-contact ref="contact" />
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import TheNavbar from '@/components/TheNavbar'
import HomeBanner from '@/components/HomeBanner'
import HomeTabs from '@/components/HomeTabs'

import HomeSolutions from '@/components/HomeSolutions'
import HomeContact from '@/components/HomeContact'
import HomeBanner2 from '@/components/HomeBanner2'
import HomeDemo from '@/components/HomeDemo'
import HomeNews from '@/components/HomeNews'

export default {
  name: 'Home',
  components: {
    TheNavbar,
    HomeTabs,

    HomeBanner,
    HomeSolutions,
    HomeContact,
    HomeBanner2,
    HomeNews,
    HomeDemo,
  },
  methods: {
    scrollTo(element) {
      const target = this.$refs[element].$el
      target.scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/_variables';
hr {
  background-color: $Chroma-03;
  height: 2px;
  margin: 0px;
}
.home {
  scroll-behavior: smooth;
}
</style>
