<template>
  <div class="home-banner">
    <div class="hero is-fullheight">
      <div class="hero-body">
        <div class="columns is-centered is-multiline">
          <div class="column is-one-third">
            <b-image :src="img" />
          </div>
          <div class="column is-one-third has-text-left">
            <p
              class="title is-1 is-spaced is-family-inter has-text-white has-text-weight-bold"
              :class="isMobile ? 'is-2' : ''"
              v-html="$t('home.banner.title')"
            ></p>
            <p
              class="subtitle is-6 is-family-bitter has-text-white has-text-left"
            >
              {{ $t('home.banner.subtitle') }}
            </p>
          </div>
          <div class="column is-full is-flex is-justify-content-center">
            <span @click="iconClick" class="icon has-text-Chroma-03">
              <i class="mdi mdi-chevron-down"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomeBanner',
  props: {
    title: {
      type: String,
      defualt: 'Default Title',
    },
    content: {
      type: String,
      defualt: 'Default Content',
    },
    img: {
      type: String,
      defualt: '',
    },
  },
  methods: {
    iconClick() {
      this.$emit('icon-click')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/_variables';

.hero-body {
  background: url('/static/Imagen-Login-01.jpg') center center;
  background-size: cover;
}
.image {
  width: 295px;
  height: auto;
}
.icon {
  font-size: 55px;
}
.title {
  line-height: 1;
}
.subtitle {
  line-height: 1.5;
}
.button {
  box-shadow: 0px 1px 4px 0px $Chroma-04-40;
  border-radius: 8px;
}
.container {
  margin-top: 3.5rem;
  margin-bottom: 2.5rem;
}
</style>
