<template>
  <div class="section has-text-centered">
    <div class="columns is-centered">
      <div class="column is-half">
        <div v-if="!isMobile" class="spacer"></div>
        <div
          class="subtitle is-1 is-spaced is-family-bitter has-text-Chroma-08"
        >
          {{ $t('home.solutions.title') }}
        </div>
        <div
          class="subtitle has-text-Dark is-6 has-text-Dark is-family-bitter"
          v-html="$t('home.solutions.subtitle')"
        ></div>
      </div>
    </div>
    <div class="columns">
      <div class="column" :class="!isMobile ? 'has-padding-top' : ''">
        <s-card
          :title="$t('home.solutions.solutions[0].title')"
          :subtitle="$t('home.solutions.solutions[0].subtitle')"
          img="/static/share-of-market.png"
        />
        <s-card-quote />
        <s-card
          :title="$t('home.solutions.solutions[1].title')"
          :subtitle="$t('home.solutions.solutions[1].subtitle')"
          img="/static/eficacia-de-las-promociones.png"
        />
      </div>
      <div class="column">
        <s-card
          :title="$t('home.solutions.solutions[2].title')"
          :subtitle="$t('home.solutions.solutions[2].subtitle')"
          img="/static/market-value.png"
        />
        <s-card
          :title="$t('home.solutions.solutions[3].title')"
          :subtitle="$t('home.solutions.solutions[3].subtitle')"
          img="/static/market-basket.png"
        />
        <s-card
          :title="$t('home.solutions.solutions[4].title')"
          :subtitle="$t('home.solutions.solutions[4].subtitle')"
          img="/static/consumer-data.png"
        />
      </div>
      <div class="column" :class="!isMobile ? 'has-padding-top' : ''">
        <s-card
          :title="$t('home.solutions.solutions[5].title')"
          :subtitle="$t('home.solutions.solutions[5].subtitle')"
          img="/static/pricing-analysis.png"
        />
        <s-card
          :title="$t('home.solutions.solutions[6].title')"
          :subtitle="$t('home.solutions.solutions[6].subtitle')"
          img="/static/audience.png"
        />
        <s-card
          :title="$t('home.solutions.solutions[7].title')"
          :subtitle="$t('home.solutions.solutions[7].subtitle')"
          img="/static/canales-emergentes.png"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SCard from '@/components/SCard'
import SCardQuote from '@/components/SCardQuote'

export default {
  name: 'HomeSolutions',
  components: {
    SCard,
    SCardQuote,
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/_variables';
.has-padding-top {
  padding-top: 10rem;
}
.section {
  background-color: $Light;
}
.card {
  margin-bottom: 2rem;
}
.column {
  padding-bottom: 0px;
}
.spacer {
  padding: 1rem;
}
</style>
