<template>
  <div class="section">
    <div class="columns is-centered">
      <div
        class="column is-half has-text-centered"
        :class="!isMobile ? 'custom-padding' : ''"
      >
        <div class="title is-spaced">
          {{ $t('home.news.title') }}
        </div>
        <div class="subtitle">
          {{ $t('home.news.subtitle') }}
        </div>
        <b-field type="is-Chroma-03-75" custom-class="custom-label">
          <b-input
            :placeholder="$t('home.news.button-placeholder')"
            custom-class="custom-input"
            type="email"
            expanded
          ></b-input>
          <p class="control">
            <b-button
              :label="$t('home.news.button')"
              type="is-Chroma-07"
              inverted
            />
          </p>
        </b-field>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomeNews',
  components: {},
  data() {
    return {
      email: '',
    }
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/variables';

.section {
  background-color: $Chroma-08;
}
.custom-padding {
  padding: 2rem 3rem;
}
.title {
  color: $white;
  line-height: 1.2;
  letter-spacing: -0.32px;
  font-weight: $weight-bold;
  font-family: $font-family-inter;
}
.subtitle {
  color: $Chroma-02;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.29px;
  font-family: $font-family-bitter;
}
.button {
  border-radius: 8px;
  letter-spacing: 0.23px;
  border: 1px solid $Chroma-04-40;
  font-weight: $weight-medium;
  font-family: $font-family-inter;
  box-shadow: 0px 1px 4px 0px $Chroma-04-40;
}
</style>
