<template>
  <div class="tab-section">
    <div class="title is-5 has-text-Chroma-05-60 is-family-inter">
      {{ about }}
    </div>
    <div class="subtitle is-1 has-text-Chroma-08 is-family-bitter">
      {{ section }}
    </div>
    <div
      v-html="content"
      class="subtitle is-6 has-text-Dark is-family-bitter"
    ></div>
  </div>
</template>
<script>
export default {
  name: 'SnTabSection',
  props: {
    about: {
      type: String,
      default: 'Acerca de la Compañía:',
    },
    section: {
      type: String,
      default: 'Qué hacemos',
    },
    content: {
      type: String,
      default:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet augue accumsan tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar, tortor eros facilisis libero, vitae commodo nunc quam et ligula. Ut nec ipsum sapien. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer id nisi nec nulla luctus lacinia non eu turpis. Etiam in ex imperdiet justo tincidunt egestas. Ut porttitor urna ac augue cursus tincidunt sit amet sed orci.',
    },
  },
}
</script>
<style scoped>
.title {
  line-height: 1.25;
  letter-spacing: 0.05px;
}
.subtitle.is-1 {
  line-height: 1.087;
  letter-spacing: -0.49px;
}
.subtitle.is-6 {
  line-height: 1.5;
  letter-spacing: 0.29px;
}
</style>
