<template>
  <div class="card">
    <div
      class="has-text-centered is-family-bitter"
      :class="isMobile ? 'card-content-mobile' : 'card-content'"
    >
      <div class="subtitle is-5 has-text-Chroma-08 has-text-weight-medium">
        <q>{{ $t('home.solutions.quotes') }} </q>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SCard',
  props: {
    content: {
      type: String,
      default: 'Default Title',
    },
    img: {
      type: String,
      default: 'https://bulma.io/images/placeholders/1280x960.png',
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/_variables';
.card {
  background-color: $Chroma-01;
  border-radius: 10px;
  border: 2px solid white;
  box-shadow: 0px 1px 2px 1px $Chroma-05-40, 0px 2px 10px 0px $Chroma-03-50;
  width: 370px;
  height: auto;
}
.card-content-mobile {
  padding: 3rem 1.25rem;
}
.card-content {
  padding: 7rem 2.5rem;
}
.subtitle {
  line-height: 1.714;
  padding-left: 3rem;
  padding-right: 3rem;
}
</style>
