import Vue from 'vue'

Vue.mixin({
  data() {
    return {
      isMobile: window.innerWidth < 769 ? true : false,
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 769 ? true : false
    })
  },
})
